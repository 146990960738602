.newsite-container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.create-site-paper {
    height: auto;
    width: 90%;
    max-width: 800px;
}

.create-site-textfield {
    text-align: right;
}

.create-site-button {
    text-align: center !important;
    margin: 20px auto 40px !important;
}

.dialog-image {
    height: 240px;
    width: 250px;
    border: 5px solid #333;
}

.url-label {
  margin-top: 5px !important;
}

.rotate {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); 
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }