.test {
  display: flex;
  cursor: pointer;
}

.tiles {
  display: flex;
  font-size: 0;
  text-align: center;
  flex-wrap: wrap;
}
.tiles .tile {
  display: inline-block;
  margin: 10px;
  text-align: left;
  opacity: 0.99;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.tiles .tile:before {
  content: "";
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.7))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  width: 100%;
  height: 50%;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.tiles .tile img {
  display: block;
  max-width: 100%;
  max-height: 200px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.tiles .tile .details {
  font-size: 16px;
  padding: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.tiles .tile .details span {
  display: block;
  opacity: 0;
  position: relative;
  top: 100px;
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.tiles .tile .details .title {
  line-height: 1;
  font-weight: 600;
  font-size: 18px;
}
.tiles .tile .details .info {
  line-height: 1.2;
  margin-top: 5px;
  font-size: 12px;
}
.tiles .tile:focus:before,
.tiles .tile:focus span,
.tiles .tile:hover:before,
.tiles .tile:hover span {
  opacity: 1;
}
.tiles .tile:focus:before,
.tiles .tile:hover:before {
  top: 50%;
}
.tiles .tile:focus span,
.tiles .tile:hover span {
  top: 0;
}
.tiles .tile:focus .title,
.tiles .tile:hover .title {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.tiles .tile:focus .info,
.tiles .tile:hover .info {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.bulletin__modal-img {
  max-width: 100%;
}

.bulletin__modal-details {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.bulletin__modal-title {
  text-transform: capitalize;
}

.bulletin__modal-info,
.bulletin__modal-title {
  margin-left: 15px !important;
  color: #fff !important;
}
