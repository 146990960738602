a {
    text-decoration: none !important;
}

.loading-container {
    text-align: center;
}

.siteinfo__container-paper {
    min-height: 700px;
    background-color: #efefef !important;
    padding: 50px;
}

.siteinfo__header {
    text-align: center;
    color: #FFFFFF !important;
    /* color: #9f9f9f !important; */
    background-color: #37373e;
    padding: 15px 0px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* .siteinfo__paper {
    background-color: #efefef;
} */

.siteinfo__site-info-text {
    text-align: center;
    padding-top:25px;
    text-decoration: underline;
}

.siteinfo__text-bold {
    font-weight: bold;
    text-align: center;
}

.siteinfo__text-normal {
    text-align: left;
    font-size: 19px;
    color:#000;
}

.siteinfo__info-paper {
    background-color: #fff;
    margin-bottom: 20px;
    height:590px;
    overflow: auto;
}

.siteinfo__files-paper {
    background-color: #fff;
    margin-bottom: 20px;
    height:700px;
    padding:10px;
    overflow: auto;
}

.siteinfo__files-info-header {
    padding: 25px;

}

.siteinfo__app-info-paper {
    background-color: #fff;
    margin-bottom: 20px;
    max-height: 100px;
}

.siteinfo__iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.siteinfo__site-status {
    flex-grow: 1;
    /* height: 300px;
    width:300px; */
}

.edit-button {
    margin:20px !important;
}

.b2b-paper {
    background-color: #fff;
    padding-bottom: 50px;
    height: 600px;
    overflow: auto;
}

.b2b-table-cell {
    width: 100px;
    table-layout: fixed;
}

/* Switch */
.container {
    text-align: center;
    }
    .toggle-switch {
    position: relative;
    width: 100px;
    display: inline-block;
    text-align: left;
    top: 8px;
    }
    .checkbox {
    display: none;
    }
    .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #060;
    color: #fff;
    }
    .inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #FF0000;
    color: #fff;
    text-align: right;
    }
    .switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 60px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
    right: 0px;
    }
    