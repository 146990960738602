@media only screen and (max-width: 80000px) {
    .card {
      background-color: #a2abb7 !important;
    }
  
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .message-text-field {
    width: 75%;
    background-color: #FFFFFF;
  }

  .video-form-text {
    color: #DEDCDC;
  }

  .divider {
    margin:50px !important;
  }
  
  .text-field {
    width: 34%;
    margin-right: 3% !important;
    margin-left: 3% !important;
    margin-bottom: 3% !important;
    
  }
  
    .about-container {
    text-align: center;
    margin-bottom: 5%;
  }
  
  .video-container {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
  
  
  .about-tab-container {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .about-tab-container-mobile{
      display: none;
  }
  
  .tab-links.tab-links {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .contact-form.contact-form {
    margin: 0 auto;
    padding:20px;
    max-width: 40%;
    background-color: #373e3e;
    margin-top:100px;
  };
  
  
  .version-text.version-text { 
    margin-left: 10px;
  }
  
  .about-version-grid {
    display: flex;
    align-items: center;
  }
  
  .version-text {
    color: #4f4e4e;
  }
  
  .contact-paper {
      max-width: 50%;
      margin-bottom: 100px;
  }
  
  .about-paper {
      max-width: 50%;
      margin-bottom: 100px;
  }
  
  .video-popup {
      min-width: 100%;
  }
  
  .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
    }
    
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  .video-grid {
    display: flex;
    justify-content: center;
  }
  
  .video-grid-paper.video-grid-paper {
    display: flex;
    align-items: center;
    justify-content: center;
    width:85%;
    padding: 50px;
    margin-bottom: 70px;
    background-color: #d8e6f8;
  }
  
  .video-links {
    text-decoration: none;
    color: #333;
    width: 1500px;
  }

  .video-count {
    margin-bottom: 50px !important;
  }

  .video-button {
    background-color: white !important;
  }

  .menu-items { 
      text-align: center;
      color: #6A6A6A
  }
  
  .tab-links {
    display: flex;
    align-items: center;
      text-decoration: none;
      font-size: medium;
      font: sans-serif;
      color: #4f4e4e;
  }
  
  .to-top-links.to-top-links {
      margin:auto;
      text-decoration: none;
      font-size :large;
      font: sans-serif;
      color: #4f4e4e;
      font-weight: bold;
  }
  
  .links {
    text-decoration: none;
    font-weight: bold;
    color: #333333;
  }
  
  .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .video-grid-paper p{
    text-align: center;
  }
  
  .contact-button.contact-button {
    margin-bottom: 10px;
    margin-top: 70px;
    background-color:rgb(199, 205, 212);
    width:30%;
    height: 50px;
    border-radius: 5px;
  };

  .container {
    text-align: center;
  }

  }
  
  @media only screen and (max-width: 1024px) {
  
    .about-tab-container.about-tab-container {
      display: none;
    }
    .about-tab-container-mobile.about-tab-container-mobile {
      display: block;
    }
    .about-tab-paper.about-tab-paper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .message-text-field {
      width: 100%;
    }
  
    .text-field {
      width: 100%;
    }
  }
  
  
  