.home__content {
  margin-top: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.home__bulletin {
  margin: 5px;
  width: 100%;
}

.home__activity {
  margin: 5px;
  width: 100%;
}

.home__announcements {
  margin-bottom: 5px;
}

.home__sales {
  margin-top: 5px;
}

@media only screen and (max-width: 700px) {
  .home__content {
    display: flex;
    flex-direction: column;
  }
  .home__bulletin {
    width: 100%;
  }

  .home__activity {
    width: 100%;
  }
}

.home__appbar {
  background-color: #d3e7fa !important;
  border-radius: 5px;
}

.home__announcement-header {
  color: rgba(0, 0, 0, 0.7) !important;
  margin-bottom: 10px !important;
}

.home__announcement_paper {
  margin: 10px;
  background-color: #37373e !important;
  width: 300px;
}

.home__announcement-container {
  max-height: 300px;
  overflow: auto;
}

.home__announcement-card {
  margin: 10px;
  margin-top: 50px;
  min-height: 200px;
}

.home__card-content {
  word-wrap: break-word;
}

.home__announcement-box {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.home__announcement-card:hover {
  box-shadow: 0 15px 20px rgba(10, 0, 10, 0.3);
  /* -webkit-filter: brightness(110%); */
}

.home__simple-pie-title {
  padding: 10px;
  text-align: center;
}

.home__view-more-stats {
  display: flex;
  justify-content: flex-end;
}

.annoucement-header .MuiCardHeader-subheader {
  font-size: 12px;
}
