.appbar * {
  color: #000000;
}

.spacer {
  flex: 1 1 auto;
}

.header {
  display: flex;
  justify-content: center;
  height:100px;
  /* border-radius: 5px; */
  background-color: #DEDCDC !important;
  /*background-image: url(../../images/logo.png);
  background-size: 170px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 2%; --- Use this to add logo from css instead of Header.js */
}

.logout *,
.login * {
  text-transform: capitalize;
}

.product__user-menu * {
  text-transform: capitalize;
  font-size: 14px;
}

.company-logo {
  padding: 5px;
  height: 60px;

}

.account {
  fill: #09962a;
}
