/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

a.login__google:visited,
a.login__google:link,
a.login__google:hover {
  text-decoration: none;
  color: #fff;
}

.signin__container {
  width: 400px;
  height: 400px;
  background-color: #fff;
  margin: auto;
}

.signin__google {
  padding: 20px;
}

.signin__local {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.signin__username,
.signin__password {
  width: 80%;
  margin: auto !important;
}

.signin__google-btn {
  display: flex;
  justify-content: center;
}

.sigin__local-btn {
  background-color: rgba(9, 150, 42, 0.6) !important;
  width: 240px;
}

.signin_local-btn-container {
  margin: auto;
  padding: 20px;
}
