.admin__tab-controller {
  flex-grow: 1;
}

.admin__container {
  width: 90%;
  margin: auto;
}

.admin__users {
  max-height: 550px;
  overflow: auto;
}

.admin__edit-user {
  flex-grow: 8;
}

.admin___users-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.admin__edit-user-header {
  text-align: center;
}

.admin__user-list {
  flex-grow: 2;
  max-width: 460px;
}

.admin__search-users {
  width: 80%;
}

.admin__edit-user-from {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 550px;
  overflow: auto;
}

table tbody tr td {
  padding: 10px 40px 10px 40px;
}

table tbody tr td:first-child {
  font-style: italic;
}

table tbody tr td:last-child {
  font-weight: 600;
}

.admin__form-actions {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.admin__delete-user * {
  color: rgba(255, 0, 0, 0.6);
}

.admin__update-user {
  color: rgba(9, 150, 42, 0.6) !important;
}

.Admin_create-user-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.admin__set-user-access {
  width: 80%;
  min-width: 180px;
}

.Admin__create-user-header {
  text-align: center;
}

.Admin__create-user * {
  color: rgba(9, 150, 42, 0.7);
}

.admin__announcements-container {
  height: 450px;
  overflow: auto;
}

.admin__announcements-actions {
  display: flex;
  flex-direction: row-reverse;
}

.admin__announcements-create {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

.admin__announcement-avatar {
  opacity: 0;
}

.admin__input-announcement {
  padding-bottom: 10px !important;
  width: 100%;
}

.admin__date-picker {
  display: flex;
  justify-content: space-around;
}

.admin__delete-announcement {
  color: rgba(255, 0, 0, 0.8) !important;
}

.admin__create-bulletin-container {
  display: flex;
  justify-content: flex-end;
}

.admin__bulletin-create {
  color: rgba(9, 150, 42, 0.7) !important;
}

.admin__bulletin-image-uploader {
  display: none;
}

.admin__bulletin-image-preview {
  padding: 15px;
}

.admin__bulletin-image-preview-gridList {
  width: 100%;
  transform: "translateZ(0)";
}

.admin__image-upload.admin__image-upload-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin__image-upload__preview {
  width: 34rem;
  height: 18rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.admin__image-upload__preview img {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  object-fit: cover;
}

.admin__announcement-list-item.active::after {
  content: "";
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(9, 150, 42, 0.6) solid;
}

.admin__announcement-list-item.inactive::after {
  content: "";
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(255, 0, 0, 0.6) solid;
}

.admin__announcement-list-item.future::after {
  content: "";
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(255, 165, 0, 0.6) solid;
}

.admin__announcement-info-container {
  display: flex;
  justify-content: flex-end;
}

.admin__info-expired {
  border-bottom: rgba(255, 0, 0, 0.6) solid;
  padding: 5px;
}

.admin__info-active {
  border-bottom: rgba(9, 150, 42, 0.6) solid;
  padding: 5px;
}

.admin__info-future {
  border-bottom: rgba(255, 165, 0, 0.6) solid;
  padding: 5px;
}

.admin___popover-container {
  display: flex;
  padding: 10px;
}

.admin___popover-container * {
  margin-left: 8px !important;
}

.admin__cards-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 550px;
  overflow: auto;
}

.admin__cards-container div {
  margin-left: 10px;
  margin-bottom: 10px;
}

.admin__bulletin-card {
  max-width: 345px;
  min-width: 250px;
}

.admin__card-actions {
  display: flex;
  justify-content: flex-end;
}

.admin__card-action-delete {
  color: rgba(255, 0, 0, 0.6) !important;
}

.admin__card-image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(35%);
}

.admin__tax-header {
  height: 96px;
}

.admin__tax-content {
  margin: 10px auto;
  width: 80%;
}

.admin__tax-actions {
  display: flex;
  justify-content: flex-end;
}

.admin__tax-update-btn {
  color: rgba(9, 150, 42, 0.6) !important;
}

.create-user__subtitle {
  color: rgba(0, 0, 0, 0.7);
}

.create-user__type {
  display: flex !important;
  flex-direction: row !important;
}

.create-user__radio {
  color: rgba(9, 150, 42, 0.7) !important;
}

.admin__users-list {
  overflow: auto;
  width: 100%;
}

.admin__update-customer-logo {
  margin-bottom: 10px;
  max-width: 460px;
}

.admin__upload-logo {
  padding: 20px;
  display: flex;
  width: 100%;
}

.admin__search-logo-btn {
  background-color: #fff !important;
}

.admin__upload-logo-btn {
  background-color: #fff !important;
}

.upload-logo-text {
  margin-right: 100px;
}

