.b2b__edit-table-row, 
.b2b__edit-table-divider, 
.b2b__edit-table-body,
.b2b__edit-table {
  padding: 10px 40px 10px 40px;
}

.b2b__edit-form {
    padding-top: 0px;
    padding-bottom: 20px;
    max-height: 950px;
    overflow: auto;
    width:500px;
  }

.header-text {
font-family: 'Open Sans Condensed', sans-serif !important;
text-align: center;
background-color: #37373d;
color: #9f9f9f;
}

.b2b__form-actions {
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    }

.b2b__update {
    color: #ff682c !important;
    }

.b2b__catalog {
  color: #2a9300 !important;
  height: 40px;
  }