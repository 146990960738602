.user__acount,
.user_account-data {
  width: 80%;
  margin: auto;
}

.user__account-container {
  display: flex;
  flex-direction: row;
}

.user__avatar-container {
  padding: 25px;
}

.user__avatar {
  width: 140px !important;
  height: 140px !important;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.user__username {
  text-transform: capitalize;
}

.user_username-label {
  font-size: 12px !important;
}

.user__account-info-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}

.user_account-data {
  margin-top: 14px;
}

.user__user-data {
  padding: 20px;
}

.user__btn-container {
  margin-right: auto;
  display: flex;
  flex-direction: row-reverse;
}

.user__update-btn {
  background-color: rgba(9, 150, 42, 0.6) !important;
  color: #fff !important;
}

.user__cp-container {
  display: flex;
  flex-direction: column;
}

#user-info-table {
  background-color: #fff;
}