/* .navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  } */
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #36373f;
    width: 350px;
    display: flex;
    justify-content: right;
    position: fixed;
    top: 74px;
    bottom: 0px;
    left: -350px;
    transition: 850ms;
    z-index:2;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index:2;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
    padding-left: 20px;
  }
  
  .navbar-toggle {
    background-color: #36373f;
    width: 90%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .nav-text span {
    margin: auto 10px;
    font-size: 20px;
  }
