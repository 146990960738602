html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #dedcdc;
  margin: 0px;
}

body {
  margin: 0px;
}

.app-content {
  margin: 50px 20px;
}

button:disabled,
button[disabled] {
  background-color: #cccccc !important;
}

button:disabled *,
button[disabled] * {
  color: rgba(0, 0, 0, 0.4);
}
