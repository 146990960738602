@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.site-links {
    font-style: normal;
    text-decoration: none;
    color: #838383;
    font-size: 13px;
    font-family: 'Press Start 2P', cursive;
    cursor: pointer;
}

.header-paper {
    width: 100%;
    border-radius: 5px;
    background-color: #37373e !important;
    color: #FFFFFF !important;
    text-align: center;
}

.title {
    padding: 20px;
}

.button {
    width: 100%;
}

.button-hidden {
    width: 100%;
    display: none;
}

.loading-container {
    width:261%;
    color: red
}